const CourseSettingsMenu = [
  {
    heading: 'menu',
    route: '/course-settings',
    pages: [
      {
        heading: 'Unique Courses',
        route: '/course-settings/course-alias',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen008.svg',
      },
      {
        heading: 'Course Types',
        route: '/course-settings/course-type',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen058.svg',
      },
      {
        heading: 'Course Categories',
        route: '/course-settings/course-categories',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen057.svg',
      },
      {
        heading: 'Course Sector',
        route: '/course-settings/course-sector',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen059.svg',
      },
      {
        heading: 'Training Milestone',
        route: '/course-settings/trainning-milestone',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen059.svg',
      },
    ],
  },
];

export default CourseSettingsMenu;
